<template>
  <div class="grid-container fluid">
    <div class="grid-x grid-padding-x pt-4">
      <div class="cell small-12 medium-10">
        <h1>Invoices</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-2">
      <div class="cell small-12 medium-3">
        <label>Client</label>
        <select v-model="searchClient">
          <option
            v-for="(client, index) in clientList"
            :key="`c_${index}`"
            :value="client.id">{{ client.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Brand</label>
        <select v-model="searchBrand">
          <option
            v-for="(brand, index) in brandList"
            :key="`br_${index}`"
            :value="brand.id">{{ brand.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Job Number</label>
        <input type="text" v-model="searchJobNumber">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Client</th>
              <th>Brand</th>
              <th>Job Number</th>
              <th>Invoice Number</th>
              <th>Value</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) in invoices" :key="`inv_${index}`">
              <td>{{ invoice.date | tinyDate }}</td>
              <td>{{ invoice.client.name }}</td>
              <td>{{ invoice.brand.name }}</td>
              <td>{{ invoice.job_number.job_number }}</td>
              <td>{{ invoice.id }}</td>
              <td>{{ invoice.grossValue | currency }}</td>
              <td>
                <router-link :to="`/invoices/view/${invoice.id}`">
                  <button class="button small">View</button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    pageCount() {
      return this.$store.state.estimates.estimateCount;
    },
  },
  data() {
    return {
      clientList: [],
      brandList: [],
      jobNumbers: [],
      invoices: [],
      page: 1,
      searchBrand: 0,
      searchClient: 0,
      searchJobNumber: '',
      selectedJobNumber: 0,
      years: [18, 19, 20, 21, 22, 23, 24],
    };
  },
  watch: {
    searchBrand() {
      this.setSearchParams();
      this.getInvoices();
    },
    searchClient(value) {
      const client = this.clientList.filter((clientVal) => clientVal.id === value)[0];
      if (client) {
        // eslint-disable-next-line
        const brands = client.brands;
        brands.unshift({
          clientId: client.id,
          name: 'All',
        });
        this.brandList = brands;
        this.setSearchParams();
        this.getInvoices();
      }
    },
    searchJobNumber() {
      this.setSearchParams();
      this.getInvoices();
    },
  },
  methods: {
    setSearchParams() {
      const searchValues = {};
      searchValues.brand = this.searchBrand;
      searchValues.client = this.searchClient;
      searchValues.jobNumber = this.searchJobNumber;
      this.$store.commit('setSearch', searchValues);
    },
    updateSearchParams() {
      this.searchBrand = this.$store.state.searchBrand;
      this.searchClient = this.$store.state.searchClient;
      this.searchJobNumber = this.$store.state.searchJobNumber;
      const clientId = this.searchClient;
      let client = {};
      for (let i = 0; i < this.clientList.length; i += 1) {
        if (this.clientList[i].id === clientId) {
          client = this.clientList[i];
        }
      }
      // eslint-disable-next-line
      const brands = client.brands;
      brands.unshift({ clientId: client.id, name: 'All' });
      this.brandList = brands;
    },
    getSearchLists() {
      axios.get(`/searches/getJobSearchList/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.clientList = response.data.clients;
          this.updateSearchParams();
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getInvoices() {
      axios.get(`/invoices/getAll/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.invoices = response.data.invoices;
          this.getSearchLists();
        })
        .catch(() => {
          this.failed = true;
        });
    },
    search() {
      const postData = {};
      postData.brand = this.searchBrand;
      postData.client = this.searchClient;
      postData.number = this.searchNumber;
      postData.year = this.searchYear;
      postData.jobNumber = this.searchJobNumber;
      axios.post(`/invoices/getAll.json?token=${this.token}`, postData)
        .then((response) => {
          this.invoices = response.data.invoices;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getSearchLists();
    }, 100);
  },
};
</script>
